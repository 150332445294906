<template>
  <section class="mr-2">
    <v-sheet class="custom-border pb-2" transparent v-if="!loading">
      <v-tabs class="px-5 pt-3" v-model="tab" background-color="transparent" hide-slider show-arrows>
        <v-tab
          v-for="item in items"
          :key="item"
          class="text-capitalize poppins f14 fw600 secondary--text no-spacing"
          active-class="secondary-1--text"
          style="text-spacing: 0px"
        >
          {{ item }}
        </v-tab>
        <v-spacer />
      </v-tabs>
      <v-divider class="mb-1" />
      <v-tabs-items v-model="tab" style="background-color: transparent">
        <v-tab-item v-for="item in items" :key="item">
          <AssessmentTable
            v-if="item === 'My Assessments'"
            :assessments="assessments"
            :loading = "assessmentLoading"
          />
          <AssessmentShareToOtherTable
            v-if="item === 'Shared to others'"
            :assessments="assessments"
            :loading = "assessmentLoading"
          />

          <AssessmentShareWithMeTable
            v-if="item === 'Shared with me'"
            :assessments="assessments"
            :new_assessment_uuid="new_assessment_uuid"
            :loading = "assessmentLoading"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
    <circular v-else />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import items from '../../constants/tabs/assessment/assessmentbuilder'
import AssessmentTable from "../../components/teacher/assessment/AssessmentTable.vue";
import AssessmentBuilder from "../../components/teacher/assessment/AssessmentBuilder.vue";
import AssessmentShareWithMeTable from "../../components/teacher/assessment/AssessmentShareWithMeTable.vue";
import AssessmentShareToOtherTable from "../../components/teacher/assessment/AssessmentShareToOtherTable.vue";

export default {
  components: {
    AssessmentBuilder,
    AssessmentTable,
    AssessmentShareToOtherTable,
    AssessmentShareWithMeTable,
  },
  data: () => ({
    loading: false,
    assessmentLoading: true,
    items: items,
    new_assessment_uuid: 0
  }),
  computed: {
    ...mapState("instructor", {
      assessments: (state) => state.assessments,
    }),

    tab: {
      get() {
        let tab = parseInt(this.$route.query.tab);
        return tab;
      },
      set(tab) {
        this.$router.replace({ query: { tab: tab, timestamp: Date.now() } });
      },
    },
  },
  // created() {
  //   this.assessmentLoading = true;
  //   this.getInstructorAssessmentsAction().then(() => {
  //     if( this.tab === `1`) {
  //       this.showSharedWithMeAction().then(() => {
  //         this.getInstructorsToShareAction().then(() => {
  //           this.assessmentLoading = false;
  //         })
  //       })
  //     } else if( this.tab === `2`) {
  //       this.showSharedToOtherAction().then(() => {
  //         this.getInstructorsToShareAction().then(() => {
  //           this.assessmentLoading = false;
  //         })
  //       })
  //     } else {
  //       this.getInstructorsToShareAction().then(() => {
  //         this.assessmentLoading = false;
  //       })
  //     }
  //   })
  // },
  mounted() {
    if(!this.$route.query || !this.$route.query.tab) {
      this.$router.replace({ query: { tab: 0, timestamp: Date.now() } });
    } else {
      this.$router.replace({ query: { tab: this.$route.query.tab, timestamp: Date.now() } });
    }
    if(this.$route.params.assessment_uuid) {
      this.tab = 1
      this.new_assessment_uuid = this.$route.params.assessment_uuid
    }

    this.assessmentLoading = true;
    this.getInstructorAssessmentsAction().then(() => {
      if( this.tab === `1`) {
        this.showSharedWithMeAction().then(() => {
          this.getInstructorsToShareAction().then(() => {
            this.assessmentLoading = false;
          })
        })
      } else if( this.tab === `2`) {
        this.showSharedToOtherAction().then(() => {
          this.getInstructorsToShareAction().then(() => {
            this.assessmentLoading = false;
          })
        })
      } else {
        this.getInstructorsToShareAction().then(() => {
          this.assessmentLoading = false;
        })
      }
    });
  },
  methods: {
    ...mapActions("instructor", [
      "getInstructorAssessmentsAction",
      "getInstructorsToShareAction",
      "showSharedToOtherAction",
      "showSharedWithMeAction",
    ]),
  },
  watch: {
    tab(val) {
      if( val == 2 ) {
        this.assessmentLoading = true
        this.showSharedToOtherAction().then(()=>{
          this.getInstructorsToShareAction().then(() => {
            this.assessmentLoading = false;
          })
        })
      }

      if(val == 1) {
        this.assessmentLoading = true
        this.showSharedWithMeAction().then(()=>{
          this.getInstructorsToShareAction().then(() => {
            this.assessmentLoading = false;
          })
        })
      }

      if(val == 0) {
        this.assessmentLoading = true
        this.getInstructorAssessmentsAction().then(()=>{
          this.getInstructorsToShareAction().then(() => {
            this.assessmentLoading = false;
          })
        })
      }
    },
  },
};
</script>
