<template>
  <v-dialog persistent v-model="dialog" max-width="650">
    <v-card>
      <v-card-title class="d-flex align-center justify-space-between ma-0 pa-0">
        <div class="poppins primary--text px-5 py-4 fw600">Preview</div>
        <v-btn icon @click="$emit('close')" class="mr-2">
          <v-icon size="20">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-4">
        <h3 class="secondary-1--text poppins">
          {{ data.title ? data.title : 'Untitled' }}
          <span class="secondary--text f14 roboto ml-5">{{ data.course.title }}</span>
        </h3>
        <div class="mt-3 d-flex align-center">
          <v-icon small>mdi-account-multiple</v-icon>
          <div class="" v-for="item in data.course.class_categories" :key="item.id">
            <div class="d-flex align-center roboto f14">
              <div class="secondary--text ml-2">{{ item.class_code }}</div>
              -
              <div class="secondary-1--text">{{ item.class_name }}</div>
              ,
            </div>
          </div>
        </div>
        <v-sheet outlined max-height="300" class=" my-5 overflow-y-auto">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, i) in data.assessment_questions" :key="item.id">
                  <td class="secondary-2--text">{{i+1}}.</td>
                  <td>
                    <div
                      v-html="!item.question ? '-' : $dompurifier(item.question)"
                      style="height: 20px"
                      class="overflow-y-hidden secondary-2--text fw400 roboto"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
            
          </v-simple-table>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  props: ["dialog", "data"],
  data: () => ({
    selected: [],
    loading: false,
  }),

  methods: {
    ...mapActions("instructor", []),
    ...mapMutations(["alertMutation"]),
  },
};
</script>
