<template>
    <section>
        <v-data-table
            :headers="assessmentcourse"
            :items="items"
            item-key="title"
            item-value="title"
            class="px-3 poppins f13 pa-0"
            :page.sync="page"
            hide-default-footer
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
        >
        <template v-slot:header.title="{ header }" >
            <span class="text-uppercase">{{ header.text }}</span>
        </template>
        <template v-slot:header.status="{ header }">
            <span class="text-uppercase">{{ header.text }}</span>
        </template>
        <template v-slot:header.action="{ header }">
            <span class="text-uppercase">{{ header.text }}</span>
        </template>
        <template v-slot:item.title="{ item }">
            <span class="poppins">{{ !item.title ? 'Untitled' : item.title }}</span>
        </template>
        <template v-slot:item.status="{ item }">
            <span class="poppins">{{ item.course.status }}ED</span>
        </template>
        <template v-slot:item.action="{ item }">
            <v-btn outlined
                dense
                small
                color="primary"
                @click="$emit('preview', item)"
                class="mr-2">
                <v-icon
                    small
                    outline
                >
                    mdi-eye-outline
                </v-icon>
            </v-btn>

            <v-menu transition="slide-y-transition" bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined
                        dense
                        small
                        color="primary"
                        v-bind="attrs"
                        v-on="on">
                        <v-icon
                            small
                            outline
                        >
                            mdi-dots-horizontal
                        </v-icon>
                    </v-btn>
                    <!-- <v-icon
                        v-bind="attrs"
                        v-on="on"
                        size="16"
                        color="primary"
                        outline
                        class="pa-1 mx-1 pointer"
                        >
                        mdi-dots-horizontal
                    </v-icon> -->
                </template>
                <v-list dense class="custom-border">
                    <v-list-item link  @click="$emit('move', item)">
                    <v-list-item-title class="roboto f14 d-flex align-center">
                        <v-icon size="18" class="mr-2">mdi-file-move-outline</v-icon>
                        <div class="">Move to</div>
                    </v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="$emit('duplicate', { id: item.id, course_id: item.course_id })">
                    <v-list-item-title class="roboto f14 d-flex align-center">
                        <v-icon size="18" class="mr-2">mdi-content-duplicate</v-icon>
                        <div class="">Duplicate</div>
                    </v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="$emit('remove', item)">
                    <v-list-item-title class="roboto f14 d-flex align-center">
                        <v-icon size="18" class="mr-2">mdi-trash-can-outline</v-icon>
                        <div class="">Delete</div>
                    </v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="$emit('share', item)">
                    <v-list-item-title class="roboto f14 d-flex align-center">
                        <v-icon size="18" class="mr-2">mdi-share-outline</v-icon>
                        <div class="">Share a copy</div>
                    </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
        </v-data-table>
        <v-row align="center" class="ma-2" v-if="itemLength > 0">
            <v-col lg="12">
                <v-pagination
                v-model="page"
                :length="pageCount"
                :total-visible="7"
                circle
                ></v-pagination>
            </v-col>
        </v-row>
    </section>
</template>

<script>
import { assessmentcourse } from '../../../constants/tblheaders/assessment'

export default {
    props: ["items", "itemLength"],
    data: () =>  ({
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        assessmentcourse,
    })
}
</script>